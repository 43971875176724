import React from 'react'
import './Hero.css'
import Header from '../Headers/Header'
import Calories from '../../assets/calories.png'

import athlete from '../../assets/Athlete.png'
import hero_image_back from '../../assets/hero_image_back.png'
import NumberCounter from 'number-counter'
import { motion } from 'framer-motion'
const Hero = () => {
    const transition={type:'spring',duration:3}
    const mobile = window.innerWidth<=768?true:false;
    return (
        <div className="hero" id='home'>
            <div className="left-h">
                <Header/>

                <div className="the-best-ad">
                    <motion.div
                    initial={{left:mobile?'110px':'150px',borderRadius:'2rem'}}
                    whileInView={{left:'35px',borderRadius:'0rem'}}
                    transition={{...transition,type:'tween'}}
                    ></motion.div>
                    <span>The best club in the town</span>
                </div>

                <div className="hero-text">
                    <div>
                        <span className='stroke-text'>Z</span>
                        <span> -</span>
                        <span>Athletes</span>
                        
                    </div>
                    <div className='dialogue'>
                        <span>A Gym in Hojai town</span>
                    </div>
                    <div>
                        <span>
                            In here we will help you to shape and build your ideal body and live up your life to fullest
                        </span>
                    </div>
                </div>
                <div className="figures">
                    <div>
                        <span>
                        <NumberCounter end={2} start={0} delay='0.1'/>
                        </span>
                        <span>expert trainers</span>
                    </div>
                    <div>
                        
                        <span><NumberCounter end={50} start={10} delay='0.5' preFix="+"/></span>
                        <span>fitness programs</span>
                    </div>
                </div>
                <div className="hero-buttons">
                    <button className='btn'>Get Started</button>
                    <button className='btn'>Learn More</button>
                </div>
            </div>
            <div className="right-h">
                <div className="btn"><a href="#">Login</a></div>
                <div className="sec_btn"><a href="#">SignUp</a></div>
                <motion.div
                initial={{right:"-1rem"}}
                whileInView={{right:"4rem"}}
                transition={transition}
                className="heart-rate">
                    <img src={Calories} alt="" />
                    <span>Calories Burnt</span>
                    <span>1000 kcal</span>
                </motion.div>
                <img src={athlete} alt="" className='hero-image'/>
                <motion.img 
                initial={{right:'11rem'}}
                whileInView={{right:'23rem'}}
                transition={transition}
                src={hero_image_back} alt="" className='hero-image-back'/>
            </div>
        </div>
    )
}

export default Hero