import './App.css';
import Plan from './components/Plans/Plan';
import Choose from './components/Chooseus/Choose';
import About from './components/AboutUs/About';
import Hero from './components/Hero/Hero';
import Programs from './components/Programs/Programs';
import Testimonial from './components/Testimonials/Testimonial';
import GFooter from './components/GymFooter/GFooter';
import Join from './components/Join/Join';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Benefits from './Pages/Benefit/Benefit'
import React from 'react';
function App() {
  return (
    <div className="App">
          
          <Hero/>
          <Programs/>
          <About/>
          <Choose/>
          <Plan/>
          <BrowserRouter>
            <Routes>
              <Route path='/Pages/Benefit' element={<Benefits/>}/>
            </Routes>
          </BrowserRouter>
          <Testimonial/>
          <Join/>
          <GFooter/>

          
    </div>
    
  );
}

export default App;
