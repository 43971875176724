import React from 'react'
import './GFooter.css'
import Instagram from "../../assets/instagram.png";
import Youtube from "../../assets/YouTube.png";
import Logo from "../../assets/Logo.jpg"
import YoutubeMobile from "../../assets/YoutubeMobile.png"


const GFooter = () => {
  const mobile = window.innerWidth<=768?true:false;
  return (
    <div className="Footer-container">
        <hr />
        <div className='footer'>
            <div className="social-links">
                <a href="https://www.instagram.com/z_athletes_/" target='blank'><img src={Instagram} alt="" /></a>
                <a href="https://www.youtube.com/results?search_query=z+athletes" target='blank'><img src={mobile?YoutubeMobile:Youtube} alt="" /></a>
            </div>
            <div className="logo-f">
                <img src={Logo} alt="" />
            </div>
        </div>
    </div>
  )
}

export default GFooter
