import React from 'react'
import './About.css'
import image1 from "../../assets/image1.png";
import image2 from "../../assets/image2.png";
import image3 from "../../assets/image3.png";
import image4 from "../../assets/image4.png";

const About = () => {
  return (
    <div className="Abouts" id='abouts'>
        <div className="left-r">
            <img src={image1} alt="" />
            <img src={image2} alt="" />
            <img src={image3} alt="" />
            <img src={image4} alt="" />
        </div>
        <div className="right-r">
          <span>About Us</span>
          <li>A B.Tech and Mathematics Post Graduate, with their passion for sports and strength, join hands to answer Hojai's strength questions. </li>
          <li>Pratik, a crazy sports lover since the day he can recall consciousness from. An ex wannabe fast bowler whose dreams were cut short due to injuries caused by improper technique and improper strength training. But the journey made him dive deep into the ocean of strength training. </li>
          <li>Arunava, his best friend, a skinny boy, kind of insecure about his physique, with a desire to get stronger and bigger, had the same enthusiasm. Pratik taught him the basics to begin with. Arunava soon, began to transform. The two kept learning from each other. The 'Z Athletes' were born.</li>
          <li>Z Athletes isn't just about fitness. It's  about strength- both physical and mental. It isn't just a gym. It represents our vision and everything we stand for. Z Athletes encapsulates our love for BHARATIYA CULTURE. A blend, of STRENGTH, both physical and mental and CULTURE. For only a strong BHARATIYA can stand for his BHARATIYA CULTURE. The interior of our gym, is themed around BHARATIYA CULTURE. </li>
          <li>Z Athletes, also, is deeply influenced by the anime Dragon Ball Z. That's where the 'Z' comes from. Kids from 90s and 2000s know, what Goku, Vegeta, Gohan, etc. meant and how much, these fictional characters played a part in inspiring people to workout, to SURPASS THEIR LIMITS, become a super saiyan version of themselves. Z Athletes is all about SURPASSING YOUR LIMITS (सीमान अतिक्रम्य in Sanskrit).</li>
          <li>Being promoters of BHARATIYA CULTURE, we promote vegetarian fitness. At the end of the day, it comes down to people's choices. But we want to promote the idea of this, not being a choice, but a responsibility of us, humans to be so strong, that we can act out of compassion, not out of compulsion, that we don't succumb to our sensory pleasures and unnecessarily take innocent lives just for the sake of taste. We want to play our little part, if any, in stopping animal cruelty. अहिंसा परमॊ धर्मः</li>
        </div>
    </div>
  )
}

export default About
