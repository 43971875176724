import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";


export const trainersData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
    name: 'ANURAVA SAMADDAR',
    status : 'COACH'
  },
  {
    image: image2,
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ipsam, ab itaque nam perferendis impedit sint ',
    name: 'PRATIK KAR',
    status: 'COACH'
  }
];