import React from 'react'
import {plansData} from '../../data/plansData'
import whiteTick from '../../assets/whiteTick.png'
import './Plan.css'
const Plan = () => {
    const mobile = window.innerWidth<=768?true:false;

  return (
    <div className="plans-container" id='plans'>
        <div className="programs-headers" style={{gap:mobile?'2rem':'10rem'}}>
            <span className='stroke-text'>READY TO START</span>
            <span>YOUR JOURNEY</span>
            <span className='stroke-text'>NOW WITH US</span>
        </div>
        <div className='plans'>
            {plansData.map((plan,i)=>(
                <div className="plan">
                    {plan.icon}
                    <span>{plan.name}</span>
                    <span>Rs.{plan.price}</span>

                    <div className="features">
                        {plan.features.map((feature,i)=>(
                            <div className="feature">
                                <img src={whiteTick} alt="" />
                                <span key={i}>{feature}</span>
                            </div>
                        ))}
                    </div>

                    <div>
                        <span><a href="/Pages/Benefit">See more benefits</a></span>
                    </div>
                    <a href="#" className='btn'><button>Join Now</button></a>
                </div>
            ))}

        </div>
    </div>
  )
}

export default Plan
