import React from 'react'
import './Benefit.css'
const Benefit = () => {
  return (

      <table>
        <caption className='stroke-text'>More Benefits</caption>
      <tr>
        <th>Basic Plan</th>
        <th>Premium Plan</th>
        <th>Pro Plan</th>
      </tr>
      <tr>
        <td>2 hours of excercises</td>
        <td>5 hour of excercises</td>
        <td>8 hours of excercises</td>
      </tr>
      <tr>
        <td>Free consultaion to coaches</td>
        <td>Free consultaion of Coaches</td>
        <td>Consultation of Private Coach</td>
      </tr>
      <tr>
        <td>Access to The Community</td>
        <td>Access to minibar</td>
        <td>Free Fitness Merchandises</td>
      </tr>
    </table>
      
    
  )
}

export default Benefit
